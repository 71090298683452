import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import modalImg from "../assets/man_working_on_computer.jpg";

const DemoCallback = ({ showModal, setShowModal, selectedCourse }) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [alert, setAlert] = useState({ type: "", message: "" });
  const generateGoogleCalendarUrl = (courseName, date, time) => {
    const eventName = encodeURIComponent(`Demo: ${courseName}`);
    const details = encodeURIComponent(
      "Join this 30-minute demo to explore the course in detail."
    );
    const location = encodeURIComponent("Online");
    const timezone = encodeURIComponent("Asia/Kolkata");

    const startDate = new Date(`${date}T${time}`);
    const endDate = new Date(startDate.getTime() + 30 * 60000); // 30 minutes later

    const formatDate = (date) => {
      return date.toISOString().replace(/-|:|\.\d+/g, "");
    };

    const startDateTime = formatDate(startDate);
    const endDateTime = formatDate(endDate);

    const attendees = encodeURIComponent("codebitonline@gmail.com");

    return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${eventName}&dates=${startDateTime}/${endDateTime}&details=${details}&location=${location}&ctz=${timezone}&add=${attendees}`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!date || !time) {
      setAlert({ type: "danger", message: "Please select both date and time." });
      return;
    }

    // Generate the Google Calendar URL
    const calendarUrl = generateGoogleCalendarUrl(selectedCourse, date, time);

    // Open the Google Calendar with the scheduled event
    window.open(calendarUrl, "_blank");

    // Show success message and close the modal
    setAlert({ type: "success", message: "Your meeting has been scheduled!" });
    setTime("")
    setDate("")
    setTimeout(() => setShowModal(false), 2000); 
    
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    showModal && (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
        <button
          onClick={handleClose}
          className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <form
          onSubmit={handleSubmit}
          className="border-2 md:w-1/2 w-11/12 px-2 py-8 h-auto border-gray-200 bg-white  rounded-md flex items-center gap-10 justify-center"
        >
          <div className="md:flex hidden w-1/4">
            <img src={modalImg} alt="" className=" h-auto overflow-hidden" />
          </div>
          <div className="w-2/3">
            <p className="mb-3 text-xl font-semibold">
              Book a Demo Class Today!
            </p>
            <p className="mb-3 text-base ">
              Experience our demo class and learn more about our offerings.
              Whether you're a beginner or an expert, our classes cater to all
              levels.
            </p>
            <div className="mb-3">
              <label htmlFor="date" className="text-white">Select Date</label>
              <input
                type="date"
                id="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="block w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="time" className="text-white">Select Time</label>
              <input
                type="time"
                id="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                step="1800"
                className="block w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            <button
              type="submit"
              className="px-6 my-4 w-full py-2 bg-purple-600 hover:border-purple-600 border-2 hover:text-purple-600 duration-700 hover:bg-white text-white rounded-full"
            >
              Schedule Meeting
            </button>
          </div>
        </form>
      </div>
    )
  );
};

export default DemoCallback;
