import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import logo from "../assets/brand_logo.png";
import { Menu, X } from "lucide-react";

const Header = () => {
  const location = useLocation(); // Get the current location
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);

  // Use useMemo to memoize the navLinks array
  const navLinks = useMemo(
    () => [
      {
        id: 1,
        route: "/courses",
        title: "Courses",
      },
      {
        id: 2,
        route: "/project-support",
        title: "Project Support",
      },
      {
        id: 3,
        route: "/resume",
        title: "Resume",
      },
      {
        id: 4,
        route: "/demo",
        title: "Demo Class",
      },
      {
        id: 5,
        route: "/contact",
        title: "Contact",
      },
    ],
    []
  );

  useEffect(() => {
    const currentPath = location.pathname;
    const activeLink = navLinks.find((nav) => nav.route === currentPath);
    if (activeLink) {
      setActive(activeLink.title);
    } else {
      setActive("Home"); 
    }
  }, [location, navLinks]); 

  return (
    <nav className="flex bg-white z-0 items-center justify-between px-6 py-2 pt-4">
      <a
        href="/"
        className="logo flex items-center gap-2"
        onClick={() => setActive("Home")}
      >
        <img src={logo} className="w-7 h-7" alt="Codebit logo" />
        <h1 className="text-black font-medium md:text-2xl text-xl">
          Codebit.in
        </h1>
      </a>

      {/* Desktop Navigation Links */}
      <ul className="list-none text-black md:flex hidden items-center justify-center flex-1">
        {navLinks.map((nav, index) => (
          <li
            key={nav.id}
            className={`font-poppins duration-300 hover:text-slate-600 hover:font-semibold cursor-pointer text-[16px] ${
              active === nav.title ? "font-medium" : "font-normal"
            } ${index === navLinks.length - 1 ? "mr-0" : "mr-10"}`}
            onClick={() => setActive(nav.title)}
          >
            <a href={nav.route}>{nav.title}</a>
          </li>
        ))}
      </ul>

      {/* Mobile Menu Button */}
      <div className="md:hidden flex flex-1 justify-end items-center cursor-pointer">
        {toggle ? (
          <X onClick={() => setToggle(!toggle)} />
        ) : (
          <Menu onClick={() => setToggle(!toggle)} />
        )}

        {/* Mobile Navigation Links */}
        <div
          className={`${
            !toggle ? "hidden" : "flex"
          } p-6 bg-white absolute top-14 z-10 right-0 mx-4 my-2 min-w-[140px] rounded-xl border-2 sidebar`}
        >
          <ul className="list-none text-black flex flex-col justify-end items-start flex-1">
            {navLinks.map((nav, index) => (
              <li
                key={nav.id}
                className={`font-poppins cursor-pointer text-[16px] ${
                  active === nav.title ? "font-bold" : "font-normal"
                } ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}
                onClick={() => {
                  setActive(nav.title);
                  setToggle(false); // Close menu on item click
                }}
              >
                <a href={nav.route}>{nav.title}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="fixed md:hidden  bottom-0 left-0 flex h-32 w-full justify-center items-end pb-6 bg-gradient-to-t from-white via-white lg:static lg:h-auto lg:w-auto lg:bg-none">
        <div className="px-6 w-screen ">
          <a
            className="flex w-auto justify-center text-white bg-slate-950 border-slate-950 border-2  duration-700 hover:bg-slate-800 rounded-lg md:px-6 px-3  py-2 "
            href="/demo"
          >
            Book Classes
          </a>
        </div>
      </div>
      <a className="pl-2" href="https://zippy.codebit.in/" target="blank">
<button class="uiverse">
    <div class="wrapper">
        <span>AI Resume</span>
        <div class="circle circle-12"></div>
        <div class="circle circle-11"></div>
        <div class="circle circle-10"></div>
        <div class="circle circle-9"></div>
        <div class="circle circle-8"></div>
        <div class="circle circle-7"></div>
        <div class="circle circle-6"></div>
        <div class="circle circle-5"></div>
        <div class="circle circle-4"></div>
        <div class="circle circle-3"></div>
        <div class="circle circle-2"></div>
        <div class="circle circle-1"></div>
    </div>
</button>
</a>

    </nav>
  );
};

export default Header;
