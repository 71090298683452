import Header from "../components/Header";
import Footer from "../components/Footer";
import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import { Typewriter } from "react-simple-typewriter";
import SEO from "../components/SEO";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const initialFormData = {
  name: "",
  email: "",
  referredBy: "",
  phone: "",
};

const BookDemo = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [alert, setAlert] = useState({ type: "", message: "" });
  const [words, setWords] = useState([
    "Web Development",
    "Python",
    "App Development",
    "JavaScript",
    "Flutter",
    "React",
    "Django",
    "NodeJS",
    "Spring",
    "Data Science",
    "Artificial Intelligence",
    "Machine Learning",
    "Blockchain",
  ]);

  useEffect(() => {
    shuffleWords();
  }, []);

  const shuffleWords = () => {
    const shuffledWords = [...words];
    for (let i = shuffledWords.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledWords[i], shuffledWords[j]] = [
        shuffledWords[j],
        shuffledWords[i],
      ];
    }
    setWords(shuffledWords);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "DemoClass"), { formData });
      setAlert({ type: "success", message: "Demo booked successfully!" });
      setFormData(initialFormData);
    } catch (error) {
      console.log(error);
      setAlert({
        type: "danger",
        message: "An error occurred. Please try again.",
      });
    }
  };

  return (
    <div>
      <SEO
  title="Book a Free Coding Demo | Explore Our Courses - Codebit.in"
  description="Interested in our coding courses? Book a free demo session to experience our interactive learning platform, meet our instructors, and get your questions answered."
  keywords="coding demo, free demo, programming demo, web development demo, book a demo, online coding courses"
  canonicalUrl="https://www.codebit.in/book-demo" 
/>

   
    <div className="h-screen flex flex-col md:justify-between  ">
      <div>
        <Header />
      </div>
      <div class="flex flex-col md:flex-row h-full md:justify-between items-start md:items-center justify-center px-0 md:px-12 p-6">
        <div class="md:w-1/2">
          <h1 class="h-44 md:h-auto font-medium text-2xl md:text-5xl ">
            Discover with Us
            <br />
            <span class="text-gradient font-semibold text-5xl md:text-7xl">
              <Typewriter
                words={words}
                loop={5}
                cursor
                cursorStyle="_"
                typeSpeed={150}
                deleteSpeed={50}
                delaySpeed={1500}
              />
            </span>
          </h1>
        </div>
        <div class="md:w-1/2 w-full flex items-center justify-center md:pl-8">
          <main className="flex w-full  bg-fuchsia-100 rounded-lg py-8 md:w-auto flex-col  px-8  ">
            <h1 className="text-2xl font-semibold pb-8">Book demo</h1>
            <form onSubmit={handleSubmit} className="w-11/12 md:w-auto ">
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-600  font-semibold"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border  border-gray-300 rounded-md focus:outline-none focus:border-fuchsia-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="referredBy"
                  className="block text-gray-600  font-semibold"
                >
                  Referred By
                </label>
                <input
                  type="text"
                  id="referredBy"
                  name="referredBy"
                  value={formData.referredBy}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border  border-gray-300 rounded-md focus:outline-none focus:border-fuchsia-500"
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-gray-600 font-semibold"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border  border-gray-300 rounded-md focus:outline-none focus:border-fuchsia-500"
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-gray-600  font-semibold"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border  border-gray-300 rounded-md focus:outline-none focus:border-fuchsia-500"
                    required
                  />
                </div>

                <div className="text-center">
                  <div className={`alert alert-${alert.type}`} role="alert">
                    {alert.message}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="w-full px-6 mb-2 md:mb-0 py-2  text-white bg-slate-950 border-slate-950 border-2  duration-700 hover:bg-slate-800 rounded-lg"
              >
                Request Callback
              </button>
            </form>
          </main>
        </div>
      </div>
      <Footer />
    </div>
    </div>
  );
};

export default BookDemo;
