import React from "react";

const Footer = () => {
  return (
    <div className="flex md:flex-row flex-col items-center justify-between px-6 md:pb-4 pb-32">
      <div className="flex text-black gap-x-4 pt-6 md:pt-0 items-center">
        <a
          href="https://www.instagram.com/codebitonline/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="bi bi-instagram text-lg  hover:text-pink-500"></i>
        </a>
        <a
          href="https://wa.me/+919994908702"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="bi bi-whatsapp text-lg  hover:text-green-500"></i>
        </a>
        <a href="mailto:contact@zentivx.com">
          <i className="bi bi-envelope text-2xl hover:text-red-500"></i>
        </a>
        <a
          href="https://www.linkedin.com/company/codebitin/"
          rel="noopener noreferrer"
        >
          <i className="bi bi-linkedin text-2xl hover:text-blue-800"></i>
        </a>
      </div>

      <div className="flex md:flex-row flex-col md:gap-5 gap-1 justify-center h-full items-center md:justify-end w-full md:text-base text-sm">
        <p className="text-black md:text-end text-center text-sm">
          {" "}
          <a href="/privacy">Privacy Policy</a>{" "}
        </p>
        <p className="text-black md:text-end text-center text-sm">
          {" "}
          <a href="/terms">Terms and Conditions</a>
        </p>

        <p className="text-black md:text-end flex items-center gap-0.5 text-center">
          <i className="bi bi-c-circle"></i>
          <span className="text-sm">Codebit.in - All rights reserved </span>
        </p>
      </div>

      <div></div>
    </div>
  );
};

export default Footer;
