import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { addDoc, collection } from "firebase/firestore";
import db from "../firebase";
import modalImg from "../assets/man_working_on_computer.jpg"; 

const ProjectSupportForm = ({ showModal, setShowModal, projectType }) => {
  const [value, setValue] = useState("");
  const [alert, setAlert] = useState({ type: "", message: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "projectSupport"), {
        value,
        projectType,
      });
      setAlert({ type: "success", message: "Thank you! We'll call you soon." });
      setShowModal(false);
    } catch (error) {
      console.log(error);
      setAlert({
        type: "danger",
        message: "An error occurred. Please try again.",
      });
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    showModal && (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
        <button
          onClick={handleClose}
          className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <form
          onSubmit={handleSubmit}
          className="border-2 md:w-1/2 w-11/12 px-2 py-8 h-auto  border-gray-200 bg-white  rounded-md flex items-center gap-10 justify-center"
        >
          <div className="md:flex hidden w-1/4">
            <img src={modalImg} alt="" className=" h-auto overflow-hidden" />
          </div>
          <div className="w-2/3">
            <p className="mb-3 text-xl font-semibold">
              Get Expert Help with Your Project!
            </p>
            <p className="mb-3 text-base ">
              Need help with your coding project? We're here to assist! <br />
            </p>
            <div>
              <PhoneInput
                placeholder="Enter phone number"
                rules={{ required: true }}
                defaultCountry="IN"
                value={value}
                onChange={setValue}
                required
              />
            </div>
            <button
              type="submit"
              className=" px-6 my-4 w-full md:mb-0 py-2 bg-purple-600 hover:border-purple-600 border-2 hover:text-purple-600 duration-700 hover:bg-white text-white rounded-full"
            >
              Request Callback
            </button>
            <div
              className={`alert text-center alert-${alert.type}`}
              role="alert"
            >
              {alert.message}
            </div>
          </div>
        </form>
      </div>
    )
  );
};

export default ProjectSupportForm;
