import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SEO from "../components/SEO";
import ProjectSupportForm from "../components/ProjectSupportForm";
import { CheckCircle2, Code2, Lightbulb } from "lucide-react";
import feedback from "../assets/man_and_woman_working_at_desk.png";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const CollegeProject = () => {
  const [showModal, setShowModal] = useState(false);
  const [projectType, setProjectType] = useState("");

  const handleProjectSelection = (type) => {
    setProjectType(type);
    setShowModal(true);
  };

  const testimonials = [
    {
      text: "Codebit's guidance was exceptional. They streamlined our project workflow and saved us valuable time.",
      author: "Diya Sharma",
      location: "Mumbai",
    },
    {
      text: "The expertise provided by Codebit helped us overcome critical challenges in our app development.",
      author: "Rohan Sharma",
      location: "Bangalore",
    },
    {
      text: "Their team was responsive and knowledgeable, providing clear solutions to our complex queries.",
      author: "Sneha Patel",
      location: "Ahmedabad",
    },
    {
      text: "We improved our website's performance significantly thanks to Codebit's expert advice.",
      author: "Anjali Singh",
      location: "Delhi",
    },
    {
      text: "Codebit's professionals were a pleasure to work with, making the process smooth and efficient.",
      author: "Anita Nair",
      location: "Chennai",
    },
  ];

  return (
    <div>
      <SEO
        title="Project Support | Get Help with Your Coding Projects - Codebit.in"
        description="Need help with your coding project? Our expert developers provide guidance, code review, and troubleshooting to help you succeed."
        keywords="project support, coding help, programming assistance, code review, debugging, project consultation"
        canonicalUrl="https://www.codebit.in/project-support"
      />

      <div className="flex h-screen flex-col justify-between">
        <div className="h-screen ">
          <Header />

          <main className=" md:text-center  md:flex sm:h-screen items-center justify-center  rounded-xl  p-6">

          <img src={feedback} alt="codebit.in" className="mt-6 md:hidden flex" />
          <div className="md:relative md:max-w-6xl block z-10">
            <h1 className="md:text-7xl  md:flex text-4xl py-0 md:pb-8 font-semibold ">
            Stuck on Your Coding Project? We Can Help!
            </h1>
            <h2 className="md:text-2xl text-base text-slate-600">
            Build a standout project that impresses your professors.
            </h2>
            <div className="py-8 flex md:gap-8   md:items-center md:justify-center justify-between">
            <button
                  onClick={() => setShowModal(true)}
                  className="  text-white  bg-slate-950 border-slate-950 border-2  duration-700 hover:bg-slate-800 rounded-lg md:px-6 px-3 py-2 "
                >
                  Request Support
                </button>
            </div>
          </div>
        </main>
        </div>

        <section className="bg-purple-50 ">
          <div className="p-6">
            <div className="text-center py-12 space-y-4">
              <span className="border bg-purple-200 text-sm font-medium rounded-lg px-3 py-1">
               Why Us
              </span>
              <h2 className="text-3xl tracking-tighter sm:text-4xl md:text-5xl font-semibold">
              Why Choose Codebit for Project Support?
              </h2>
            </div>
            <div className="flex md:flex-row flex-col gap-16 pb-10 items-center justify-center">
              <div className="flex flex-col items-center justify-between text-center gap-2 max-w-md">
<div className="bg-blue-100 flex items-center justify-center rounded p-4">

              <Code2 className="text-4xl text-blue-500 w-10 h-auto mx-auto " />
</div>
                <div>
                  <h3 className="text-xl font-medium">Experienced Developers</h3>
                  <p>
                  Our team consists of seasoned developers with expertise in
                  various technologies and project domains.
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between text-center gap-2  max-w-md">
              <div className="bg-green-100 flex items-center justify-center rounded p-4">
              <CheckCircle2 className="text-4xl text-green-500 w-10 h-auto mx-auto " />
                </div>
                <div>
                  <h3 className="text-xl font-medium">Proven Track Record</h3>
                  <p>
                  We have a history of successfully assisting students with their
                  coding projects, ensuring high-quality outcomes.
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between text-center gap-2 max-w-md">
              <div className="bg-yellow-100 flex items-center justify-center rounded p-4">
              <Lightbulb className="text-4xl text-yellow-400 w-10 h-auto mx-auto " />
                </div>
                <div>
                  <h3 className="text-xl font-medium">Personalized Guidance</h3>
                  <p>
                  We provide tailored support based on your specific project
                  requirements and learning goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-emerald-50 py-32 md:px-24">
          <div className="p-6 flex md:flex-row flex-col items-center md:items-start justify-between">
            <div className="md:py-0 py-6">
              <h2 className=" md:text-7xl md:text-start text-center text-3xl font-semibold">
                Success Stories From
                <br /> Our Students
              </h2>
            </div>
            <div className="md:w-1/3 w-full">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 12000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {testimonials.map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <div className="pb-8">
                      <p className="mb-4">"{testimonial.text}"</p>
                      <p className="py-2">
                        <span className="font-semibold">
                          {testimonial.author}
                        </span>{" "}
                        - {testimonial.location}
                      </p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className=" py-16">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl font-bold  mb-4">
              Ready to Ace Your Project?
            </h2>
            <p className="text-lg mb-8">
              Get in touch with us today for expert project support.
            </p>
            <button
              onClick={() => setShowModal(true)} 
              className="  text-white  bg-slate-950 border-slate-950 border-2  duration-700 hover:bg-slate-800 rounded-lg md:px-6 px-3 py-2 "
            >
             Talk to an Expert
            </button>
          </div>
        </section>

        <ProjectSupportForm
          showModal={showModal}
          setShowModal={setShowModal}
          projectType={projectType}
        />

        <Footer />
      </div>
    </div>
  );
};

export default CollegeProject;
