import React from "react";
import Header from "../components/Header";

const NoPage = () => {
  return (
    <main>
      <Header />
      <div className="h-screen flex items-center flex-col gap-4 justify-center">
        <h1 className="primary-text md:text-9xl text-4xl">404 Not Found</h1>
        <a href="/" className="text-white primary-bg rounded px-6 py-2">
          Home
        </a>
      </div>
    </main>
  );
};

export default NoPage;
