import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col justify-between h-full p-6">
      <Header />
      <main className="overflow-y-auto my-6">
        <h1 className="font-semibold text-4xl primary-text text-center">
          Privacy Policy
        </h1>
        <div className="py-8 text-gray-700">
          <p>
            At Codebit, we are committed to protecting your privacy. This
            Privacy Policy explains how we collect, use, and disclose
            information about you when you use our services.
          </p>
          <h2 className="mt-6 text-xl font-semibold">
            1. Information We Collect
          </h2>
          <p>
            We collect information about you when you register for an account,
            purchase a course, or interact with our platform. This information
            may include your name, email address, payment information, and any
            other information you provide to us.
          </p>
          <h2 className="mt-6 text-xl font-semibold">
            2. How We Use Your Information
          </h2>
          <p>
            We use the information we collect to provide, maintain, and improve
            our services, communicate with you, and personalize your experience.
            We may also use your information to send you promotional emails or
            newsletters, but you can opt out of receiving these communications
            at any time.
          </p>
          <h2 className="mt-6 text-xl font-semibold">3. Information Sharing</h2>
          <p>
            We may share your information with third-party service providers who
            help us operate our platform, process payments, or provide customer
            support. We may also share your information in response to legal
            requests or to protect our rights or the rights of others.
          </p>
          <h2 className="mt-6 text-xl font-semibold">4. Data Security</h2>
          <p>
            We take reasonable measures to protect your information from
            unauthorized access, disclosure, alteration, or destruction.
            However, no method of transmission over the internet or electronic
            storage is completely secure, so we cannot guarantee absolute
            security.
          </p>
          <h2 className="mt-6 text-xl font-semibold">5. Your Choices</h2>
          <p>
            You can update your account information or preferences at any time
            by logging into your account settings. You can also opt out of
            receiving promotional emails or newsletters by following the
            instructions in the emails we send you.
          </p>
          <h2 className="mt-6 text-xl font-semibold">
            6. Changes to This Policy
          </h2>
          <p>
            We may update this Privacy Policy from time to time. If we make any
            material changes, we will notify you by email or by posting a notice
            on our platform prior to the change becoming effective. We encourage
            you to review this Privacy Policy periodically for any updates.
          </p>
          <p className="mt-8">
            By using our services, you acknowledge that you have read,
            understood, and agree to be bound by this Privacy Policy.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
