import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ResumeCallback from "../components/ResumeCallback";
import CallbackModal from "../components/CallbackModal";
import SEO from "../components/SEO";

const ResumeSubscription = () => {
  const [showModal, setShowModal] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [showCallbackModal, setShowCallbackModal] = useState(true);

  const handleSubscriptionSelection = (type) => {
    setSubscriptionType(type);
    setShowModal(true);
  };

  // Define your subscription options
  const subscriptionOptions = [
    {
      title: "Basic Boost",
      price: 499,
      features: [
        "Optimized resume for Applicant Tracking Systems",
        "Professional resume template",
        "Resume review and feedback",
      ],
    },
    {
      title: "Pro Profile",
      price: 1999,
      features: [
        "Everything in the 'Basic Boost' plan",
        "Optimized LinkedIn profile creation",
        "LinkedIn profile review and optimization",
      ],
    },
    {
      title: "Career Complete",
      price: 2999,

      features: [
        "Everything in the 'Basic Boost' + 'Pro Profile'",
        "Optimized profiles for Naukri and Indeed job portals",
        "Profile review and optimization across all platforms",
      ],
    },
  ];

  return (
    <div>
      <SEO
        title="Resume & Profile Optimization Services | Get Noticed - Codebit.in"
        description="Boost your job search with our expert resume and LinkedIn profile optimization services. Get your profile noticed by recruiters and land your dream tech job."
        keywords="resume optimization, linkedin optimization, resume writing, profile writing, job search, tech jobs, career services"
        canonicalUrl="https://www.codebit.in/resume-subscription"
      />

      <div className="flex h-screen flex-col justify-between">
        <Header />
        <ResumeCallback
          showModal={showModal}
          setShowModal={setShowModal}
          subscriptionType={subscriptionType}
        />
        <div className="p-6">
          <h1 className="font-semibold md:text-4xl text-2xl primary-text text-center mt-4">
            Elevate Your Job Search Journey
          </h1>
          <p className="text-xl text-center py-2">
            Enhance your job search with our expert resume and profile
            optimization services.
          </p>
        </div>
        <main className="flex flex-col md:flex-row justify-center items-center md:mb-26 mx-4 gap-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-6 p-2 md:px-6">
            {subscriptionOptions.map((option, index) => (
              <div
                key={index}
                className="flex flex-col justify-between gap-4 bg-purple-100 rounded-lg p-4"
              >
                <h3 className="text-2xl font-medium">{option.title}</h3>
                <ul className="list-disc p-4">
                  {option.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <div className="flex flex-col justify-center">
                  <p className="text-xl font-semibold">
                    INR {option.price.toLocaleString()}{" "}
                  </p>
                </div>
                <button
                  onClick={handleSubscriptionSelection}
                  className="bg-purple-500 text-white rounded-lg"
                >
                  <p className="bg-inherit py-2 text-lg rounded">
                    Start Your Journey
                  </p>
                </button>
              </div>
            ))}
          </div>
        </main>

        <Footer />
      </div>
    </div>
  );
};

export default ResumeSubscription;
