import React, { useState } from "react";
import macImg from "../assets/macbook_open.png";
import "../App.css";

const CallbackModal = ({ showModal, setShowModal }) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [alert, setAlert] = useState({ type: "", message: "" });

  const generateGoogleCalendarUrl = (courseName, date, time) => {
    const eventName = encodeURIComponent(courseName);
    const details = encodeURIComponent(
      "Join this 30-minute demo to explore the course in detail."
    );
    const location = encodeURIComponent("Online");
    const timezone = encodeURIComponent("Asia/Kolkata");

    const startDate = new Date(`${date}T${time}`);
    const endDate = new Date(startDate.getTime() + 30 * 60000); // 30 minutes later

    const formatDate = (date) => {
      return date.toISOString().replace(/-|:|\.\d+/g, "");
    };

    const startDateTime = formatDate(startDate);
    const endDateTime = formatDate(endDate);

    const attendees = encodeURIComponent("codebitonline@gmail.com");

    return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${eventName}&dates=${startDateTime}/${endDateTime}&details=${details}&location=${location}&ctz=${timezone}&add=${attendees}`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!date || !time) {
      setAlert({ type: "danger", message: "Please select both date and time." });
      return;
    }

    // Generate the Google Calendar URL
    const calendarUrl = generateGoogleCalendarUrl("Demo class", date, time);

    // Open the Google Calendar with the scheduled event
    window.open(calendarUrl, "_blank");

    // Show success message and close the modal
    setAlert({ type: "success", message: "Your meeting has been scheduled!" });
    setTimeout(() => setShowModal(false), 2000);
    setTime("")
    setDate("")
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    showModal && (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
        <button
          onClick={handleClose}
          className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <form
          onSubmit={handleSubmit}
          className="lg:w-1/2 w-11/12 md:p-12 bg-black rounded-md flex items-center gap-10 justify-start p-6 px-4"
          style={{
            backgroundImage: `url(${macImg})`,
            backgroundPosition: "right bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <div>
          <p className="mb-3 md:text-7xl text-4xl  font-bold modal-gradient">
              Learn <br /> to code
            </p>
            <p className="mb-3 w-1/2 md:hidden text-xs md:text-base  flex font-semibold text-white">
              Start your journey to becoming a developer and win a MacBook Pro!*
            </p>
            <p className="mb-3 md:flex hidden font-semibold text-white">
              Start your journey to becoming a developer <br /> and win a
              MacBook Pro!*
            </p>

            <div className="mb-3">
              <label htmlFor="date" className="text-white">Select Date</label>
              <input
                type="date"
                id="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="block w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="time" className="text-white">Select Time</label>
              <input
                type="time"
                id="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                className="block w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            <button
              type="submit"
              className="px-6 my-4 w-full py-2 bg-purple-600 hover:border-purple-600 border-2 hover:text-purple-600 duration-700 hover:bg-white text-white rounded-full"
            >
              Schedule Demo
            </button>
          </div>
        </form>
      </div>
    )
  );
};

export default CallbackModal;
