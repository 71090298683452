import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CallbackModal from "../components/CallbackModal";
import heroImg from "../assets/child_coding.png";
import learn from "../assets/3d_coding_icon.png";
import path from "../assets/mountain_with_flag.png";
import jobready from "../assets/resume_and_briefcase.png";
import { Swiper, SwiperSlide } from "swiper/react";
import careerGuide from "../assets/trophy-winner-celebration.png";
import expert from "../assets/man_holding_laptop.png";
import feedback from "../assets/man_and_woman_working_at_desk.png";
import interactive from "../assets/group_of_people_working_on_a_project.png";
import recorded from "../assets/woman_recording_video.png";
import support from "../assets/group_of_people_working_together.png";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import DemoCallback from "../components/DemoCallback";
import SEO from "../components/SEO";

const Home = () => {
  const [showCallbackModal, setShowCallbackModal] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("");

  const handleSubscriptionSelection = (type) => {
    setSubscriptionType(type);
    setShowModal(true);
  };

  const features = [
    {
      id: 1,
      imageSrc: expert,
      imageAlt: "Illustration of an instructor providing expert guidance.",
      title: "Expert Instructors",
      description: "Learn from industry experts and passionate educators.",
      bgColor: "bg-[#F8F4E6]",
      class: "w-44 h-auto",
    },
    {
      id: 2,
      imageSrc: interactive,
      imageAlt: "Illustration depicting interactive learning tools.",
      title: "Interactive Learning",
      description: "Engage with hands-on coding exercises and quizzes.",
      bgColor: "bg-[#EFF6FF]",
      class: "w-44 h-auto",
    },
    {
      id: 3,
      imageSrc: careerGuide,
      imageAlt: "Illustration of a smiling woman celebrating career success.",
      title: "Career Guidance",
      description: "Get support with resumes, interviews, and career advice.",
      bgColor: "bg-[#F0FFF5]",
    },
    {
      id: 4,
      imageSrc: recorded,
      imageAlt: "Illustration of recorded class videos on a screen.",
      title: "Recorded Class Videos",
      description: "Access flexible, pre-recorded classes by professionals.",
      bgColor: "bg-[#F4F0F8]",
    },
    {
      id: 5,
      imageSrc: support,
      imageAlt: "Illustration of a supportive and collaborative community.",
      title: "Community & Support",
      description: "Connect and collaborate with our learning community.",
      bgColor: "bg-[#FFF0E5]",
    },
    {
      id: 6,
      imageSrc: feedback,
      imageAlt: "Illustration of a feedback hub for project review.",
      title: "Project Feedback Hub",
      description: "Receive constructive feedback to improve your projects.",
      bgColor: "bg-[#F2F8FF]",
    },
  ];

  return (
    <div>
      <SEO
        title="Codebit.in | Learn to Code & Launch Your Tech Career"
        description="Master in-demand coding skills with our interactive courses, build impressive projects, and get job-ready with expert guidance. Start your coding journey today!"
        keywords="coding, programming, web development, courses, online learning, tech career, python, javascript, react, django"
        canonicalUrl="https://www.codebit.in/"
      />

      <div className="flex flex-col justify-between">
        <Header />
        <CallbackModal
          showModal={showCallbackModal}
          setShowModal={setShowCallbackModal}
        />
        <DemoCallback
          showModal={showModal}
          setShowModal={setShowModal}
          courseType={subscriptionType}
        />

        <main className=" md:text-center  md:flex sm:h-screen items-center justify-center  rounded-xl  p-6">
          <div class="blur "></div>

          <img src={heroImg} alt="codebit.in" className="mt-6 md:hidden flex" />
          <div className="md:relative block z-10">
            <h1 className="md:text-8xl md:flex text-4xl py-0 md:pb-8 font-semibold ">
              Shaping Your Journey, <br /> One Bit at a Time
            </h1>
            <h2 className="md:text-2xl text-base text-slate-600">
              Learn in-demand coding skills, build stunning projects, and launch
              your dream tech career.
            </h2>
            <div className="py-8 flex md:gap-8   md:items-center md:justify-center justify-between">
              <a
                className="  text-white  bg-slate-950 border-slate-950 border-2  duration-700 hover:bg-slate-800 rounded-lg md:px-6 px-3 py-2 "
                href="/courses"
              >
                Start Learning
              </a>
              <a
                className="  text-slate-950  bg-white border-green-600 border-2 hover:bg-slate-100 duration-700  rounded-lg md:px-6 px-3 py-2 "
                href="https://zippy.codebit.in/"
                target="blank"
              >
                Build Your Resume
              </a>
            </div>
          </div>
        </main>

        {/* Features */}
        <section className="bg-purple-50 ">
          <div className="p-6">
            <div className="text-center py-12 space-y-4">
              <span className="border bg-purple-200 text-sm font-medium rounded-lg px-3 py-1">
                How it works
              </span>
              <h2 className="text-3xl tracking-tighter sm:text-4xl md:text-5xl font-semibold">
                Your Personalized Coding Journey
              </h2>
            </div>
            <div className="flex md:flex-row flex-col gap-16 pb-10 items-center justify-center">
              <div className="flex flex-col items-center justify-between text-center gap-2 max-w-md">
                <img src={path} alt="Your path" className="w-44 h-auto" />
                <div>
                  <h3 className="text-xl font-medium">Choose Your Path</h3>
                  <p>
                    Explore various programming languages and specializations to
                    find your perfect fit.
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between text-center gap-2  max-w-md">
                <img src={learn} alt="Your path" className="w-44 h-auto" />
                <div>
                  <h3 className="text-xl font-medium">Learn by Doing</h3>
                  <p>
                    Dive into interactive coding lessons, projects, and
                    real-world case studies.
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between text-center gap-2 max-w-md">
                <img src={jobready} alt="Your path" className="w-44 h-auto" />
                <div>
                  <h3 className="text-xl font-medium">Get Job-Ready</h3>
                  <p>
                    Build a standout portfolio, optimize your resume, and
                    prepare for tech interviews with expert guidance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* why us  */}
        <section className="p-6 ">
          <div className="pt-8">
            <h2 className=" text-center py-12 md:text-4xl text-2xl font-semibold">
              Unlock Your Coding Potential with Codebit.in
            </h2>
          </div>
          <div className="flex justify-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 pb-10 max-w-7xl">
              {features.map((feature) => (
                <div
                  className={`flex flex-col items-center justify-between text-center gap-2 ${feature.bgColor} rounded-lg p-4`}
                >
                  <img
                    src={feature.imageSrc}
                    alt={feature.imageAlt}
                    className="w-64 h-auto"
                  />
                  <div>
                    <h3 className="text-2xl font-medium">{feature.title}</h3>
                    <p>{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Testimonials  */}
        <section className="bg-emerald-50 py-32 md:px-24">
          <div className="p-6 flex md:flex-row flex-col items-center md:items-start justify-between">
            <div className="md:py-0 py-6">
              <h2 className=" md:text-7xl md:text-start text-center text-3xl font-semibold">
                Success Stories from <br /> Codebit Learners
              </h2>
            </div>
            <div className="md:w-1/3 w-full">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 12000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="pb-8">
                    <p>
                      Codebit.in truly transformed my career! Before joining, I
                      struggled to land interviews despite my programming
                      knowledge. With their guidance, I revamped my resume,
                      polished my coding skills, and within months, I landed my
                      dream job as a software engineer.
                    </p>
                    <p className="py-2">
                      <span className="font-semibold">Rajesh Kumar</span> -
                      Bangalore, India
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="pb-8">
                    <p>
                      I was a complete beginner when I stumbled upon Codebit.in.
                      Their interactive learning approach made coding fun and
                      engaging. Today, I'm a confident developer, thanks to
                      their expert instructors and supportive community.
                    </p>
                    <p className="py-2">
                      <span className="font-semibold">Priya Singh</span> -
                      Mumbai, India
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="pb-8">
                    <p>
                      As a working professional, I needed a flexible learning
                      platform to upskill in programming. Codebit.in's recorded
                      class videos allowed me to learn at my own pace, and the
                      project feedback hub helped me refine my skills. I highly
                      recommend it!
                    </p>
                    <p className="py-2">
                      <span className="font-semibold">Amit Patel</span> - New
                      Delhi, India
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="pb-8">
                    <p>
                      Transitioning into a tech career seemed daunting until I
                      found Codebit.in. Their career guidance and personalized
                      support were invaluable. Thanks to them, I not only landed
                      a job but also feel confident in my abilities as a coder.
                    </p>
                    <p className="py-2">
                      <span className="font-semibold">Neha Sharma</span> - Pune,
                      India
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="pb-8">
                    <p>
                      Codebit.in exceeded my expectations! Their comprehensive
                      learning paths provided a structured approach to mastering
                      coding languages. The mentorship and community support
                      helped me overcome obstacles and achieve my coding goals.
                    </p>
                    <p className="py-2">
                      <span className="font-semibold">Sandeep Gupta </span> -
                      Hyderabad, India
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="pb-8">
                    <p>
                      Joining Codebit.in was the best decision I made for my
                      career. Despite having no prior coding experience, their
                      beginner-friendly approach made learning enjoyable. Today,
                      I'm a confident programmer, all thanks to their excellent
                      curriculum and supportive community.
                    </p>
                    <p className="py-2">
                      <span className="font-semibold">Deepika Rajan </span> -
                      Chennai, Tamil Nadu
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="pb-8">
                    <p>
                      I've always been passionate about technology, but I lacked
                      direction. Codebit.in provided me with the roadmap I
                      needed to kickstart my tech career. Their job-ready
                      approach equipped me with the skills and confidence to
                      excel in interviews.
                    </p>
                    <p className="py-2">
                      <span className="font-semibold">Karthik Mani </span> -
                      Coimbatore, Tamil Nadu
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="pb-8">
                    <p>
                      Codebit.in goes above and beyond for its students! The
                      expert instructors not only teach coding but also mentor
                      you throughout your journey. Thanks to their guidance and
                      support, I was able to successfully transition into a
                      software development role.
                    </p>
                    <p className="py-2">
                      <span className="font-semibold">Saravanan Kumar </span> -
                      Madurai, Tamil Nadu
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section>
        {/* pricing */}
        <section className="p-6">
          <div className="text-center">
            <h2 className="py-6 md:text-4xl text-2xl font-semibold">
              Explore Our Courses
            </h2>
            <p className="text-xl pb-6">
              Explore our programming courses and find the perfect fit for your
              learning journey.
            </p>
            <a
              href="/courses"
              className="text-blue-500 hover:text-blue-600 hover:font-semibold duration-300"
            >
              Explore All Courses <i class="bi bi-arrow-up-right"></i>{" "}
            </a>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 py-10">
            <div className="flex flex-col  justify-between gap-4 bg-blue-100 rounded-lg p-4">
              <h3 className="text-2xl font-medium">Python Full Stack</h3>
              <p>
                A comprehensive course covering both front-end and back-end
                development using Python.
              </p>
              <div className="flex flex-col  justify-center">
                <p className="text-lg font-semibold">
                  INR 15,000{" "}
                  <span className="line-through text-sm font-normal ml-1">
                    INR 20,000
                  </span>
                </p>
              </div>
              <button
                onClick={() => handleSubscriptionSelection("Python Full Stack")}
                className="bg-blue-500 text-white rounded-lg"
              >
                <p className="bg-inherit py-2 text-lg rounded">
                  Start Your Journey
                </p>
              </button>
            </div>

            <div className="flex flex-col  justify-between gap-4 bg-blue-100 rounded-lg p-4">
              <h3 className="text-2xl font-medium">Django Backend</h3>
              <p>
                Master backend web development with Django, a high-level Python
                web framework.
              </p>
              <div className="flex flex-col  justify-center">
                <p className="text-lg font-semibold">
                  INR 10,000{" "}
                  <span className="line-through text-sm font-normal ml-1">
                    INR 12,500
                  </span>
                </p>
              </div>
              <button
                onClick={() => handleSubscriptionSelection("Django Backend")}
                className="bg-blue-500 text-white rounded-lg"
              >
                <p className="bg-inherit py-2 text-lg rounded">
                  Start Your Journey
                </p>
              </button>
            </div>

            <div className="flex flex-col ijustify-between gap-4 bg-blue-100 rounded-lg p-4">
              <h3 className="text-2xl font-medium">React Frontend</h3>
              <p>
                Build dynamic user interfaces with React, the popular JavaScript
                library for building user interfaces.
              </p>
              <div className="flex flex-col justify-center">
                <p className="text-lg font-semibold">
                  INR 8,000{" "}
                  <span className="line-through text-sm font-normal ml-1">
                    INR 10,000
                  </span>
                </p>
              </div>
              <button
                onClick={() => handleSubscriptionSelection("React Frontend")}
                className="bg-blue-500 text-white rounded-lg"
              >
                <p className="bg-inherit py-2 text-lg rounded">
                  Start Your Journey
                </p>
              </button>
            </div>

            <div className="flex flex-col  justify-between gap-4 bg-blue-100 rounded-lg p-4">
              <h3 className="text-2xl font-medium">Artificial Intelligence</h3>
              <p>
                Explore the fascinating world of Artificial Intelligence,
                machine learning, and deep learning.
              </p>
              <div className="flex flex-col justify-center">
                <p className="text-lg font-semibold">
                  INR 15,000{" "}
                  <span className="line-through text-sm font-normal ml-1">
                    INR 18,000
                  </span>
                </p>
              </div>
              <button
                onClick={() =>
                  handleSubscriptionSelection("Artificial Intelligence")
                }
                className="bg-blue-500 text-white rounded-lg"
              >
                <p className="bg-inherit py-2 text-lg rounded">
                  Start Your Journey
                </p>
              </button>
            </div>

            <div className="flex flex-col  justify-between gap-4 bg-blue-100 rounded-lg p-4">
              <h3 className="text-2xl font-medium">English for Developers</h3>
              <p>
                Enhance your communication skills with specialized English
                courses tailored for developers.
              </p>
              <div className="flex flex-col  justify-center">
                <p className="text-lg font-semibold">
                  INR 5,000{" "}
                  <span className="line-through text-sm font-normal ml-1">
                    INR 6,500
                  </span>
                </p>
              </div>
              <button
                onClick={() => handleSubscriptionSelection("English")}
                className="bg-blue-500 text-white rounded-lg"
              >
                <p className="bg-inherit py-2 text-lg rounded">
                  Start Your Journey
                </p>
              </button>
            </div>

            <div className="flex flex-col justify-between gap-4 bg-blue-100 rounded-lg p-4">
              <h3 className="text-2xl font-medium">Git and GitHub</h3>
              <p>
                Learn version control and collaboration with Git and GitHub,
                essential tools for modern developers.
              </p>
              <div className="flex flex-col  justify-center">
                <p className="text-lg font-semibold">
                  INR 3,500{" "}
                  <span className="line-through text-sm font-normal ml-1">
                    INR 4,500
                  </span>
                </p>
              </div>
              <button
                onClick={() => handleSubscriptionSelection("Git and GitHub")}
                className="bg-blue-500 text-white rounded-lg"
              >
                <p className="bg-inherit py-2 text-lg rounded">
                  Start Your Journey
                </p>
              </button>{" "}
            </div>

            <div className="flex flex-col justify-between gap-4 bg-blue-100 rounded-lg p-4">
              <h3 className="text-2xl font-medium">Node.js</h3>
              <p>
                Build scalable network applications with Node.js, a popular
                JavaScript runtime environment.
              </p>
              <div className="flex flex-col justify-center">
                <p className="text-lg font-semibold">
                  INR 9,000{" "}
                  <span className="line-through text-sm font-normal ml-1">
                    INR 11,000
                  </span>
                </p>
              </div>
              <button
                onClick={() => handleSubscriptionSelection("Node.js")}
                className="bg-blue-500 text-white rounded-lg"
              >
                <p className="bg-inherit py-2 text-lg rounded">
                  Start Your Journey
                </p>
              </button>
            </div>

            <div className="flex flex-col justify-between gap-4 bg-blue-100 rounded-lg p-4">
              <h3 className="text-2xl font-medium">MySQL</h3>
              <p>
                Learn the world's most popular open-source database, MySQL, and
                its management.
              </p>
              <div className="flex flex-col justify-center">
                <p className="text-lg font-semibold">
                  INR 7,500{" "}
                  <span className="line-through text-sm font-normal ml-1">
                    INR 9,000
                  </span>
                </p>
              </div>
              <button
                onClick={() => handleSubscriptionSelection("MySQL")}
                className="bg-blue-500 text-white rounded-lg"
              >
                <p className="bg-inherit py-2 text-lg rounded">
                  Start Your Journey
                </p>
              </button>{" "}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default Home;
