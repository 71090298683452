import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SEO from "../components/SEO";

const TermsAndConditions = () => {
  return (
    <div>
      <SEO
  title="Terms and Conditions | Codebit.in"
  description="Please read our terms and conditions carefully before using our website or enrolling in our coding courses."
  keywords="terms, conditions, codebit terms, legal, website terms"
  canonicalUrl="https://www.codebit.in/terms-and-conditions" 
/>

    
    <div className="flex flex-col justify-between h-full p-6">
      <Header />
      <main className=" overflow-y-auto my-6">
        <h1 className="font-semibold text-4xl primary-text text-center">
          Terms and Conditions
        </h1>
        <div className="py-8 text-gray-700">
          <p>
            Welcome to Codebit! By accessing or using our services, you agree to
            be bound by the following terms and conditions. Please read them
            carefully before proceeding with your course purchase.
          </p>
          <h2 className="mt-6 text-xl font-semibold">1. Course Content</h2>
          <p>
            The content of the courses offered on this platform is for
            educational purposes only. We strive to provide accurate and
            up-to-date information, but we make no warranties or guarantees.
          </p>
          <h2 className="mt-6 text-xl font-semibold">2. Payment and Refunds</h2>
          <p>
            All course fees are non-refundable. However, if you encounter any
            issues with the course content or delivery, please contact our
            support team for assistance.
          </p>
          <h2 className="mt-6 text-xl font-semibold">
            3. Intellectual Property
          </h2>
          <p>
            All course materials, including but not limited to videos, text,
            images, and exercises, are the property of our platform or our
            content providers and are protected by copyright laws. You may not
            reproduce, distribute, or modify any course materials without prior
            written permission.
          </p>
          <h2 className="mt-6 text-xl font-semibold">4. User Conduct</h2>
          <p>
            You agree to use our platform and course materials for lawful
            purposes only. You must not upload or transmit any content that is
            unlawful, harmful, threatening, abusive, harassing, defamatory,
            vulgar, obscene, or otherwise objectionable.
          </p>
          <h2 className="mt-6 text-xl font-semibold">5. Governing Law</h2>
          <p>
            These terms and conditions shall be governed by and construed in
            accordance with the laws of India. Any disputes relating to these
            terms and conditions shall be subject to the exclusive jurisdiction
            of the courts of India.
          </p>
          <p className="mt-8">
            By proceeding with your course purchase, you acknowledge that you
            have read, understood, and agree to abide by these terms and
            conditions.
          </p>
        </div>
      </main>
      <Footer />
    </div>
    </div>
  );
};

export default TermsAndConditions;
