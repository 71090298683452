import React from "react";
import { Helmet } from "react-helmet";
import ogLogo from "../assets/ogLogo.jpg";

const SEO = ({ title, description, keywords, canonicalUrl }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <link rel="canonical" href={canonicalUrl} />
    <meta property="og:image" content={ogLogo} />
  </Helmet>
);

export default SEO;
