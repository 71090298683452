import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import BookDemo from "./pages/BookDemo";
import Courses from "./pages/Courses";
import NoPage from "./pages/NoPage";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ResumeSubscription from "./pages/ResumeSubscription";
import CollegeProject from "./pages/CollegeProject";

function App() {
  return (
    <div className="App h-screen select-none">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/demo" element={<BookDemo />} />
          <Route path="/resume" element={<ResumeSubscription />} />
          <Route path="/project-support" element={<CollegeProject />} /> 
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
